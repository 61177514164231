<template>
    <div class="page-content-inner">
        <h2>{{ pageContent('change_password') }}</h2>
        <div class="uk-grid">            
            <div class="uk-width-expand@m">
                <form method="post" id="update_password" @submit.prevent="changePassword" >
                    <div class="uk-card-default rounded">
                        <div class="uk-flex uk-flex-between uk-flex-middle py-3 px-4">
                            <h5 class="mb-0">{{ pageContent('please_mention_below_details') }}</h5>                    
                        </div>
                        <hr class="m-0">
                        <div class="uk-child-width-1-2@s uk-grid-small p-4 uk-grid frmfld_blk" >                            
                            <div class="uk-form-group" id="password_block">
                                <label class="uk-form-label"> {{ formField('new_password') }}</label>
                                <div class="uk-position-relative w-100">
                                    <span class="uk-form-icon">
                                        <i class="icon-feather-lock"></i>
                                    </span>
                                    <input class="uk-input" maxlength="20" type="password" v-model.trim="$v.formData.password.$model" placeholder="******" value="" >
                                </div>
                                <p class="form-error" v-if="!formStatus && $v.formData.password.$error && !$v.formData.password.required">{{ validationRequired('new_password.required') }}</p>
                                <p class="form-error" v-if="!formStatus && $v.formData.password.$error && !$v.formData.password.minLength">{{ validationRequired('password_length') }}</p>
                            </div>
                            <div class="uk-form-group" id="password_confirmation_block">
                                <label class="uk-form-label"> {{ formField('confirm_password') }}</label>
                                <div class="uk-position-relative w-100">
                                    <span class="uk-form-icon">
                                        <i class="icon-feather-lock"></i>
                                    </span>
                                    <input class="uk-input" maxlength="20" type="password" v-model.trim="$v.formData.password_confirmation.$model" placeholder="******" value="" >
                                </div>
                                <p class="form-error" v-if="!formStatus && $v.formData.password_confirmation.$error && !$v.formData.password_confirmation.sameAsPassword && !$v.formData.password_confirmation.required">{{ validationRequired('same_password') }}</p>
                            </div>
                            
                            <div class="uk-form-group" id="current_password_block">
                                <label class="uk-form-label"> {{ formField('current_password') }}</label>
                                <div class="uk-position-relative w-100">
                                    <span class="uk-form-icon">
                                        <i class="icon-feather-lock"></i>
                                    </span>
                                    <input class="uk-input" maxlength="20" type="password" v-model.trim="$v.formData.current_password.$model" placeholder="******" value="">
                                </div>
                                <p class="form-error" v-if="!formStatus && $v.formData.current_password.$error && !$v.formData.current_password.required">{{ validationRequired('current_password.required') }}</p>
                                <p class="form-error" v-if="!formStatus && $v.formData.current_password.$error && !$v.formData.current_password.minLength">{{ validationRequired('password_length') }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="uk-card-default rounded mt-4 p-3">
                        <div class="uk-flex-middle uk-grid-small uk-grid uk-grid">  
                            <div class="uk-width-auto@s">
                                <router-link to="/user/profile" class="btn btn-default-outline" >{{ buttonText('back_to_profile') }}</router-link>
                            </div>              
                            <div class="uk-width-auto@s">
                                <button type="submit" class="btn btn-default" data-title="Update Password">{{ buttonText('update_password') }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="resMssg mt-4" v-html="formResponse"></div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import Translation from '../../../public/translation.json'

export default {
    data() {
        return {
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            formData: {
                current_password: '',
                password_confirmation: '',
                password: ''
            },
            formResponse: '',
            formStatus: true
        }
    },
    validations: {
        formData: {
            current_password: {required, minLength: minLength(6)},
            password_confirmation: {required, sameAsPassword: sameAs('password')},
            password: {required, minLength: minLength(6)}
        }
    },
    methods: {    
        validationRequired(field){
            return Translation.validation[this.defaultLang][field]
        },
        formField(field){
            return Translation.placeholder[this.defaultLang][field]
        },
        pageContent(field){
            return Translation.content[this.defaultLang][field]
        },    
        buttonText(field){
            return Translation.button[this.defaultLang][field]
        }, 
        togellingResponse(){
            setTimeout(()=>{
                document.querySelector('.resMssg').style.display = 'none'
            }, 5000)
        },
        changePassword() {
            this.formStatus = false
            this.$v.$touch()
            if(!this.$v.$invalid){ 
                document.querySelector('.resMssg').style.display = 'block'
                this.formResponse = ''
                let config = {
                    'headers': { 'Authorization': 'Bearer '+ this.$store.state.auth.token},
                }
                this.$http.post('account/user/change-password', this.formData, config).then(res=>{
                    if(res.status === 200){
                        this.resMsg = res.data.message;
                        this.formResponse =`<span class="alert alert-success">${res.data.message}</span>`  
                        this.resetForm();                        
                        this.$store.commit('authLogout')
                        window.location.href = '/account/login'
                    }
                    else {
                        this.formResponse =`<span class="alert alert-danger">${res.data.error}</span>`
                    }
                })
                .catch(err=>{
                    if(err.response.status === 500)
                        this.formResponse =`<span class="alert alert-danger">Internal Server Error!</span>`
                    else
                        this.formResponse =`<span class="alert alert-danger">${err.response.data.error}</span>`
                })
            }
            this.togellingResponse();
        },
        resetForm(){
            Object.keys(this.formData).forEach(item=>{
                this.formData[item] = ''
            })    
            this.formStatus = true
        }
    }
}
</script>